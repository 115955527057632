import { ReactElement } from "react";

const StepIcon = (): ReactElement => {
  return (
    <div>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.194891 0.219931C-0.0649643 0.512422 -0.0649643 0.987907 0.194892 1.2804L4.39256 6L0.194892 10.7196C-0.0649638 11.0121 -0.0649638 11.4876 0.194892 11.7801C0.454748 12.0733 0.881177 12.0733 1.14103 11.7801L5.80511 6.53023C6.06496 6.23774 6.06496 5.76226 5.80511 5.46977L1.14103 0.219931C0.881177 -0.07331 0.454747 -0.07331 0.194891 0.219931Z"
          fill="#DFA04A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.19489 0.219931C5.93504 0.512422 5.93504 0.987907 6.19489 1.2804L10.3926 6L6.19489 10.7196C5.93504 11.0121 5.93504 11.4876 6.19489 11.7801C6.45475 12.0733 6.88118 12.0733 7.14103 11.7801L11.8051 6.53023C12.065 6.23774 12.065 5.76226 11.8051 5.46977L7.14103 0.219931C6.88118 -0.07331 6.45475 -0.07331 6.19489 0.219931Z"
          fill="#DFA04A"
        />
      </svg>
    </div>
  );
};

export default StepIcon;
