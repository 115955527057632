import classNames from "classnames";
import { useDarkModeContext } from "../../../../src/context/DarkModeContext";
import { FirstSectionProps } from "../../../../types/FirstSectionProps";
import SeperateTitle from "../../../../utils/SeperateTitle";
import ContentLists from "./ContentLists/ContentLists";
import styles from "./FirstSection.module.scss";
import Title from "./Title/Title";
const FirstSection = ({ firstSectionData, qrCodeData }: FirstSectionProps) => {
  const { darkMode } = useDarkModeContext();
  const title =
    firstSectionData && firstSectionData.firstSectionTitle && SeperateTitle(firstSectionData.firstSectionTitle);
  const downloadTitle = firstSectionData?.firstSectionDownloadTitle;
  return (
    <section className={classNames(styles.element, "container", darkMode && styles.hasDarkMode)}>
      <div className={classNames(styles.wrapper, styles.gridWrapper)}>
        <div className={classNames(styles.content, styles.heading)}>
          <Title title={title} button={firstSectionData.firstSectionButton} />
        </div>
        <div className={classNames(styles.contentItems, styles.contents)}>
          <div className={classNames(styles.contentListWrapper)}>
            <ContentLists lists={firstSectionData.firstSectionContentList} />
          </div>
        </div>

        <figure className={classNames(styles.image, styles.figure)}>
          <img alt="" src={firstSectionData.firsSectionImage.sourceUrl} />
        </figure>
        {/* <div className={classNames(styles.visualWrapper)}>

        </div> */}

        {/* <div className={classNames(styles.download)}>
          <div className={classNames(styles.downloadDesc)}>
            <DownloadText
              title={downloadTitle}
              desc={firstSectionData.firstSectionDownloadDesc}
            />
          </div>
          {/* Removing per request by client */}
        {/* {qrCodeData && (
            <div className={classNames(styles.qrcode)}>
              <Qrcode
                downloadImageAndroid={
                  qrCodeData.firstSectionQrModalAndroidDownload.sourceUrl
                }
                downloadImageIOS={
                  qrCodeData.firstSectionQrModalIosDownload.sourceUrl
                }
                imageAndroid={qrCodeData.firstSectionQrAndroidImage.sourceUrl}
                imageIos={qrCodeData.firstSectionQrIosImage.sourceUrl}
                mobilDeviceIOS={qrCodeData.firstSectionQrCodeIos}
                mobileDeviceAndroid={qrCodeData.firstSectionQrAndroid}
                modalText={qrCodeData.firstSectionQrModalText}
                or={qrCodeData.firstSectionQrOr}
                disabled={qrCodeData.qrCodeDisable}
                style={{ padding: "12px 20px", height: "76px", width: "150px" }}
              />
            </div>
          )} 
        </div> */}
      </div>
    </section>
  );
};

export default FirstSection;
