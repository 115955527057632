const SeperateTitle = (title: string) => {
  if (title) {
    const titleStart = title.split(" ");
    if (titleStart.length > 2) {
      const titleEnd = titleStart.splice(-2, 2);
      return { titleStart: titleStart.join(" "), titleEnd: titleEnd.join(" ") };
    } else {
      return { titleStart: title, titleEnd: "" };
    }
  }

  return null;
};

export default SeperateTitle;
