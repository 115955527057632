import classNames from "classnames";
import styles from "./SectionTitle.module.scss";
import { SectionTitleProps } from "./type";

const SectionTitle = ({ sectionDesc, sectionTitle, className }: SectionTitleProps) => {
  return (
    <div data-testid="sectionTitle" className={classNames(styles.sectionTitle, className)}>
      <span>{sectionTitle}</span>
      <h4>{sectionDesc}</h4>
    </div>
  );
};

export default SectionTitle;
