import classNames from "classnames";
import { ReactElement } from "react";
import races from "../../../../../assets/images/races.jpg";
import { SingleCardProps } from "../../../../../types/SingleCardProps";
import styles from "./SingleCard.module.scss";
import StepIcon from "./StepIcon";

const SingleCard = ({
  title,
  desc,
  distance,
  price,
  status,
  time,
  quantity,
  currentPlaces,
  maxPlaces,
  num,
}: SingleCardProps): ReactElement => {
  return (
    <div className={classNames(styles.element)}>
      <div className={classNames(styles.contentWrapper)}>
        <figure>
          <img src={races.src} alt="" />
        </figure>
        <div className={classNames(styles.content)}>
          <h4>{title}</h4>
          <p>{desc}</p>
        </div>
      </div>
      <div className={classNames(styles.properties)}>
        <div>{quantity}</div>
        <div>{price}</div>
        <div>{num}</div>
        <div>{distance}</div>
        <div>{time}</div>
        <div>{status}</div>
        <div>
          {currentPlaces}/{maxPlaces}
        </div>
      </div>
      <StepIcon />
    </div>
  );
};

export default SingleCard;
