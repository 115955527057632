import classNames from "classnames";
import Link from "next/link";
import Button from "../../../Button/Button";
import SectionTitle from "../../../SectionTitle/SectionTitle";
import SingleCard from "./SingleCard/SingleCard";
import styles from "./UpcomingRaces.module.scss";

const data = [
  {
    uid: 0,
    title: "Golden Oaks Day Dash",
    desc: "Bergen, NO",
    distance: "1400m",
    price: "Free",
    time: "6d 22h",
    status: "Ongoig",
    quantity: 2,
    maxPlaces: 16,
    currentPlaces: 3,
    num: 60,
  },
  {
    uid: 1,
    title: "Racing In The Life Artois",
    desc: "Lion, FR",
    distance: "700m",
    price: 60,
    time: "4d 8h",
    status: "Ongoig",
    quantity: 1,
    maxPlaces: 14,
    currentPlaces: 7,
    num: 195,
  },
  {
    uid: 3,
    title: "Racing In The Life Artois",
    desc: "Lion, FR",
    distance: "700m",
    price: 60,
    time: "4d 8h",
    status: "Ongoig",
    quantity: 1,
    maxPlaces: 14,
    currentPlaces: 7,
    num: 195,
  },
  {
    uid: 4,
    title: "Racing In The Life Artois",
    desc: "Lion, FR",
    distance: "700m",
    price: 60,
    time: "4d 8h",
    status: "Ongoig",
    quantity: 1,
    maxPlaces: 14,
    currentPlaces: 7,
    num: 195,
  },
  {
    uid: 5,
    title: "Racing In The Life Artois",
    desc: "Lion, FR",
    distance: "700m",
    price: 60,
    time: "4d 8h",
    status: "Ongoig",
    quantity: 1,
    maxPlaces: 14,
    currentPlaces: 7,
    num: 195,
  },
];

const UpcomingRaces = ({ upcomingRacesSectionData }: { upcomingRacesSectionData: any }) => {
  return (
    <section className={classNames(styles.element, "container")}>
      <div className={classNames(styles.upcomingContainer)}>
        <div className={classNames(styles.title)}>
          <SectionTitle
            sectionTitle={upcomingRacesSectionData.upcomingRacesTitle}
            sectionDesc={upcomingRacesSectionData.upcomingRacesDesc}
          />
          <Link href={upcomingRacesSectionData.upcomingRacesButton?.url || "/"}>
            <Button text={upcomingRacesSectionData.upcomingRacesButton?.title} className={classNames(styles.button)} />
          </Link>
        </div>
        <div className={classNames(styles.wrapper)}>
          {data.map((t) => (
            <SingleCard
              desc={t.desc}
              title={t.title}
              quantity={t.quantity}
              distance={t.distance}
              price={t.price}
              status={t.status}
              time={t.time}
              maxPlaces={t.maxPlaces}
              num={t.num}
              currentPlaces={t.currentPlaces}
              key={t.uid}
            />
          ))}
        </div>
        <Button className={classNames(styles.buttonMobile)} text="View all" />
      </div>
    </section>
  );
};

export default UpcomingRaces;
