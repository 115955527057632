/* eslint-disable @next/next/no-img-element */
import classNames from "classnames";
import { useDarkModeContext } from "../../../../../src/context/DarkModeContext";
import { CharacterCardProps } from "../../../../../types/CharacterCardProps";
import styles from "./CharacterCard.module.scss";

const CharacterCard = ({ image, title, desc }: CharacterCardProps) => {
  const { darkMode } = useDarkModeContext();
  return (
    <div className={classNames(styles.element)}>
      <div className={classNames(styles.leftSide, darkMode && styles.hasDarkMode)}>
        <img src={image} alt="" />
      </div>
      <div className={classNames(styles.rightSide)}>
        <h4>{title}</h4>
        <p>{desc}</p>
      </div>
    </div>
  );
};

export default CharacterCard;
