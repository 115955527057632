import classNames from "classnames";
import React, { useEffect } from "react";
import Modal from "react-modal";
import { Close } from "../../components/Icons";
import styles from "./QRcode.module.scss";
import { QRCodeProps } from "./type";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    border: "2px solid #F4F2ED",
    backdropFilter: "blur(44px)",
  },
  overlay: {
    background: "rgba(36, 33, 33, 0.3)",
    zIndex: 999999,
  },
};

const QRCode = (props: QRCodeProps) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const { mobileDevice, style } = props;
  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [modalIsOpen]);
  return (
    <>
      <div className={classNames(styles.element)} style={style} onClick={!props.disabled ? openModal : closeModal}>
        <div className={classNames(styles.title)}>
          <span>{mobileDevice}</span>
        </div>
        <div className={classNames(styles.image, props.disabled && styles.disabled)}>
          {mobileDevice === "ANDROID" ? (
            <figure>
              <img src={props.imageANDROID} />
            </figure>
          ) : (
            <figure>
              <img src={props.imageIOS} />
            </figure>
          )}
        </div>
      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <span onClick={closeModal} className={classNames(styles.closeModal)}>
          <Close />
        </span>
        <div className={classNames(styles.modalWrapper)}>
          <h4>{props.modalText}</h4>
          <div className={classNames(styles.modalImage)}>
            {mobileDevice === "ANDROID" ? (
              <figure>
                <img src={props.imageANDROID} />
              </figure>
            ) : (
              <figure>
                <img src={props.imageIOS} />
              </figure>
            )}
          </div>
          <p>{props.or?.toLowerCase()}</p>
          <div className={classNames(styles.downloadImage)}>
            {mobileDevice === "ANDROID" ? (
              <figure>
                <img src={props.downloadImageAndroid} />
              </figure>
            ) : (
              <figure>
                <img src={props.downloadImageIOS} />
              </figure>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default QRCode;
