import classNames from "classnames";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import mock from "../../../../assets/images/mock.png";
import SeperateTitle from "../../../../utils/SeperateTitle";
import Qrcode from "../../../QRcode/Qrcode/Qrcode";
import styles from "./DownloadingSection.module.scss";

const DownloadingSection = ({ aboutAppSectionData, qrCodeData }: { aboutAppSectionData: any; qrCodeData: any }) => {
  const desc = SeperateTitle(aboutAppSectionData.aboutAppSectionDesc);
  const downloadTitle = SeperateTitle(aboutAppSectionData.aboutSectionDownloadTitle);
  return (
    <section className={classNames(styles.element, "container")}>
      <span className={classNames(styles.title)}>{aboutAppSectionData.aboutAppSectionTitle}</span>
      <h4 className={classNames(styles.description)}>
        {desc?.titleStart}
        <span> {desc?.titleEnd}</span>
      </h4>
      <div className={classNames(styles.content)}>
        <div className={classNames(styles.contentDescription)}>
          <div>
            <Swiper
              autoHeight={true}
              modules={[Pagination, Navigation]}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              className={classNames(styles.contentSlider)}
            >
              {aboutAppSectionData.aboutAppSectionContentSliders.map((t: any) => (
                <SwiperSlide key={t.aboutAppSectionContentSlider} className={classNames(styles.contentSliderSlide)}>
                  <h4>{t.aboutAppSectionContentSlider}</h4>
                  <p>{t.aboutAppSectionContentSliderDesc}</p>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={classNames(styles.contentDescQrcode)}>
            <h4>
              {downloadTitle?.titleStart}
              <span> {downloadTitle?.titleEnd}</span>
            </h4>
            {qrCodeData && (
              <div className={classNames(styles.contentDescQrcodeQr)}>
                <Qrcode
                  downloadImageAndroid={qrCodeData.firstSectionQrModalAndroidDownload.sourceUrl}
                  downloadImageIOS={qrCodeData.firstSectionQrModalIosDownload.sourceUrl}
                  imageAndroid={qrCodeData.firstSectionQrAndroidImage.sourceUrl}
                  imageIos={qrCodeData.firstSectionQrIosImage.sourceUrl}
                  mobilDeviceIOS={qrCodeData.firstSectionQrCodeIos}
                  mobileDeviceAndroid={qrCodeData.firstSectionQrAndroid}
                  modalText={qrCodeData.firstSectionQrModalText}
                  or={qrCodeData.firstSectionQrOr}
                  style={undefined}
                  disabled={qrCodeData.qrCodeDisable}
                />
              </div>
            )}
          </div>
        </div>
        <figure className={classNames(styles.contentImage)}>
          {/* <Image
            src={mock.src}
            alt=""
            layout="responsive"
            width={397}
            height={267}
          /> */}
          <img src={mock.src} alt="" />
        </figure>
      </div>
    </section>
  );
};

export default DownloadingSection;
