import QRCode from "../QRcode";
import { QrcodeProps } from "./type";

const Qrcode = ({
  downloadImageAndroid,
  downloadImageIOS,
  imageAndroid,
  imageIos,
  mobilDeviceIOS,
  mobileDeviceAndroid,
  modalText,
  or,
  style,
  disabled,
}: QrcodeProps) => {
  return (
    <>
      <QRCode
        mobileDevice={mobilDeviceIOS?.toUpperCase()}
        style={style}
        imageIOS={imageIos}
        modalText={modalText}
        downloadImageIOS={downloadImageIOS}
        or={or}
        disabled={disabled}
      />
      <span>{or}</span>
      <QRCode
        mobileDevice={mobileDeviceAndroid?.toUpperCase()}
        style={style}
        imageANDROID={imageAndroid}
        modalText={modalText}
        or={or}
        downloadImageAndroid={downloadImageAndroid}
        disabled={disabled}
      />
    </>
  );
};

export default Qrcode;
