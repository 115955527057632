import classNames from "classnames";
import { ContentListItemProps } from "../../../../../../types/ContentListItemProps";
import styles from "./ContentListItem.module.scss";
const ContentListItem = ({ title, desc, image }: ContentListItemProps) => {
  return (
    <div className={classNames(styles.element)}>
      <img className={classNames(styles.image)} src={image} alt="" />
      <div className={classNames(styles.content)}>
        <h4>{title}</h4>
        <p>{desc}</p>
      </div>
    </div>
  );
};

export default ContentListItem;
