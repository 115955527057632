import classNames from "classnames";
import Link from "next/link";
import { useDarkModeContext } from "../../../../src/context/DarkModeContext";
import Button from "../../../Button/Button";
import styles from "./Community.module.scss";

const Community = ({ communityData }: { communityData: any }) => {
  const { darkMode } = useDarkModeContext();
  return (
    <section className={classNames(styles.element, "container")}>
      <div className={classNames(styles.wrapper, darkMode && styles.hasDarkMode)}>
        <div className={classNames(styles.content)}>
          <h4>
            {communityData?.communitySectionTitle} <span>{communityData?.communitySectionGoldTitle}</span>{" "}
            {communityData?.communitySectionTitleEnd}
          </h4>
          <p>{communityData?.communitySectionDesc}</p>
          <div className={classNames(styles.buttons)}>
            <Link href={communityData?.communitySectionFirstButton?.url || "/"}>
              <a>
                <Button text={communityData?.communitySectionFirstButton.title} width="medium" />
              </a>
            </Link>
            <Link href={communityData?.communitySectionSecondButton.url || "/"}>
              <a>
                <Button text={communityData?.communitySectionSecondButton.title} width="medium" transparent={true} />
              </a>
            </Link>
          </div>
        </div>
        <img className={classNames(styles.image)} src={communityData.communitySectionImage?.sourceUrl} alt="" />
      </div>
    </section>
  );
};

export default Community;
