import { ContentListsProps } from "../../../../../types/ContentListsProps";
import ContentListItem from "./ContentListItem/ContentListItem";

const ContentLists = ({ lists }: ContentListsProps) => {
  return (
    <>
      {lists?.map((el: any, i: any) => {
        return (
          <ContentListItem
            title={el.firstSectionContentListItemTitle}
            desc={el.firstSectionContentListItemDesc}
            image={el.firstSectionContentListItemImage.sourceUrl}
            key={i}
          />
        );
      })}
    </>
  );
};

export default ContentLists;
