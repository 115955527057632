import classNames from "classnames";
import CharacterCard from "./CharacterCard/CharacterCard";
import styles from "./Characters.module.scss";

const Characters = ({ charactersSectionData }: { charactersSectionData: any }) => {
  return (
    <section className={classNames(styles.element, "container")}>
      {charactersSectionData.charactersBoxes?.map((el: any, i: number) => {
        return (
          <CharacterCard
            image={el.charactersCardImage.sourceUrl}
            title={el.charactersCardTitle}
            desc={el.charactersCardDesc}
            key={el.charactersCardTitle}
          />
        );
      })}
    </section>
  );
};

export default Characters;
