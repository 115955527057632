import classNames from "classnames";
import Link from "next/link";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "../../../Button/Button";
import styles from "./LatestNews.module.scss";

const LatestNewsMobile = ({ latestNewsData, latestNewsTitle }: any) => {
  const news = latestNewsData?.data?.posts?.nodes;
  const title = latestNewsTitle?.latestSectionTitle || "";

  return (
    <section className={classNames(styles.element, "container")}>
      <div className={classNames(styles.content)}>
        <div className={classNames(styles.contentDescription)}>
          <span className={classNames(styles.title)}>{title}</span>
          <div>
            <Swiper
              modules={[Pagination, Navigation]}
              slidesPerView={1}
              pagination={{ clickable: true }}
              className={classNames(styles.contentSlider, "newsSlider", "newsSliderMobile")}
            >
              {news && news.length > 0 ? (
                news?.map((t: any) => (
                  <SwiperSlide key={t.id} className={classNames(styles.contentSliderSlide)}>
                    <div className={classNames(styles.swiperSlideWrapper)}>
                      <div>
                        <h4>{t.title}</h4>
                        <div dangerouslySetInnerHTML={{ __html: t.excerpt }} />
                      </div>
                      <div>
                        <figure className={classNames(styles.imageMobile)}>
                          <img alt="Featured Image" src={t.featuredImage.node.sourceUrl} />
                        </figure>
                        <Link href={`/news/article${t.uri}`}>
                          <a>
                            <Button text="Read article" width="small" />
                          </a>
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              ) : (
                <SwiperSlide className={classNames(styles.contentSliderSlide)}>
                  <h4>There is not any news</h4>
                </SwiperSlide>
              )}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LatestNewsMobile;
