import { gql } from "@apollo/client";
import Home from "../components/PageComponents/Home/Home";
import { client } from "../lib/apollo";

import { aboutAppSectionQuery } from "../lib/queries/aboutappSection";
import { charactersSectionQuery } from "../lib/queries/charactersSection";
import { communitySectionQuery } from "../lib/queries/communitySection";
import { firstSectionDraftQuery, firstSectionQuery } from "../lib/queries/firstsection";
import { languageQuery } from "../lib/queries/language";
import { latestNewsTitleQuery, newsItemsQuery } from "../lib/queries/newspage";
import { onlineStreamSectionQuery } from "../lib/queries/onlineStreamSection";
import { qrCodeQuery } from "../lib/queries/qrCode";
import { upcomingRacesSectionQuery } from "../lib/queries/upcomingRacesSection";
import { visitMarketplaceSectionQuery } from "../lib/queries/visitMarketplaceSection";

import { useLanguageContext } from "../src/context/LanguageContext";
import { useWalletStateContext } from "../src/context/WalletStateContext";
import { IHomePageSections } from "../types/IHomePageSections";

const Main = ({
  firstsectionData,
  latestNewsData,
  aboutAppData,
  onlineStreamData,
  upcomingRacesData,
  visitMarketplaceData,
  charactersSection,
  communitySectionData,
  qrCodeData,
  latestNewsDataTitle,
}: IHomePageSections) => {
  // Display the version number in the console
  const { lang } = useLanguageContext();
  const walletState = useWalletStateContext();
  const {
    wallet: {
      activeWallet: { access, refresh },
    },
  } = walletState;

  return (
    <>
      <Home
        firstsectionData={firstsectionData[lang.code]}
        latestNewsData={latestNewsData[lang.code]}
        aboutAppData={aboutAppData[lang.code]}
        onlineStreamData={onlineStreamData[lang.code]}
        upcomingRacesData={upcomingRacesData[lang.code]}
        visitMarketplaceData={visitMarketplaceData[lang.code]}
        charactersSection={charactersSection[lang.code]}
        communitySectionData={communitySectionData[lang.code]}
        qrCodeData={qrCodeData[lang.code]}
        latestNewsDataTitle={latestNewsDataTitle[lang.code]}
      />
    </>
  );
};

export default Main;

export async function getStaticProps(context: any) {
  const languages = await client.query({
    query: gql`
      ${languageQuery}
    `,
  });

  const firstSectionData: any = {};
  if (context.preview) {
    for (let i = 0; i < languages.data.languages.length; i++) {
      firstSectionData[languages.data.languages[i].code] = await client.query({
        query: gql`
          ${firstSectionDraftQuery}
        `,
        variables: {
          language: languages.data.languages[i].code,
        },
      });
    }
  } else {
    for (let i = 0; i < languages.data.languages.length; i++) {
      firstSectionData[languages.data.languages[i].code] = await client.query({
        query: gql`
          ${firstSectionQuery}
        `,
        variables: {
          language: languages.data.languages[i].code,
        },
      });
    }
  }

  const newsItems: any = {};
  for (let i = 0; i < languages.data.languages.length; i++) {
    newsItems[languages.data.languages[i].code] = await client.query({
      query: gql`
        ${newsItemsQuery}
      `,
      variables: {
        language: languages.data.languages[i].code,
      },
    });
  }

  const latestNewsDataTitle: any = {};
  for (let i = 0; i < languages.data.languages.length; i++) {
    latestNewsDataTitle[languages.data.languages[i].code] = await client.query({
      query: gql`
        ${latestNewsTitleQuery}
      `,
      variables: {
        language: languages.data.languages[i].code,
      },
    });
  }

  const aboutAppData: any = {};
  for (let i = 0; i < languages.data.languages.length; i++) {
    aboutAppData[languages.data.languages[i].code] = await client.query({
      query: gql`
        ${aboutAppSectionQuery}
      `,
      variables: {
        language: languages.data.languages[i].code,
      },
    });
  }

  const onlineStreamData: any = {};
  for (let i = 0; i < languages.data.languages.length; i++) {
    onlineStreamData[languages.data.languages[i].code] = await client.query({
      query: gql`
        ${onlineStreamSectionQuery}
      `,
      variables: {
        language: languages.data.languages[i].code,
      },
    });
  }

  const upcomingRacesData: any = {};
  for (let i = 0; i < languages.data.languages.length; i++) {
    upcomingRacesData[languages.data.languages[i].code] = await client.query({
      query: gql`
        ${upcomingRacesSectionQuery}
      `,
      variables: {
        language: languages.data.languages[i].code,
      },
    });
  }

  const visitMarketplaceData: any = {};
  for (let i = 0; i < languages.data.languages.length; i++) {
    visitMarketplaceData[languages.data.languages[i].code] = await client.query({
      query: gql`
        ${visitMarketplaceSectionQuery}
      `,
      variables: {
        language: languages.data.languages[i].code,
      },
    });
  }

  const charactersSectionData: any = {};
  for (let i = 0; i < languages.data.languages.length; i++) {
    charactersSectionData[languages.data.languages[i].code] = await client.query({
      query: gql`
        ${charactersSectionQuery}
      `,
      variables: {
        language: languages.data.languages[i].code,
      },
    });
  }

  const communitySectionData: any = {};
  for (let i = 0; i < languages.data.languages.length; i++) {
    communitySectionData[languages.data.languages[i].code] = await client.query({
      query: gql`
        ${communitySectionQuery}
      `,
      variables: {
        language: languages.data.languages[i].code,
      },
    });
  }

  const qrCodeData: any = {};
  for (let i = 0; i < languages.data.languages.length; i++) {
    qrCodeData[languages.data.languages[i].code] = await client.query({
      query: gql`
        ${qrCodeQuery}
      `,
      variables: {
        categoryName: `${languages.data.languages[i].code}-qrcode`,
      },
    });
  }

  return {
    props: {
      firstsectionData: firstSectionData,
      latestNewsData: newsItems,
      latestNewsDataTitle,
      aboutAppData,
      onlineStreamData,
      upcomingRacesData,
      visitMarketplaceData: visitMarketplaceData,
      charactersSection: charactersSectionData,
      communitySectionData: communitySectionData,
      qrCodeData: qrCodeData,
    },
  };
}
