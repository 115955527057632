import classNames from "classnames";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useUiStateContext } from "../../../../../src/context/UiStateContext";
import { TitleProps } from "../../../../../types/NewsCardTitleProps";
import Button from "../../../../Button/Button";
import WalletModal from "../../../../WalletModal/WalletModal";
import styles from "./title.module.scss";

const Title = ({ title, button }: TitleProps) => {
  const router = useRouter();

  const {
    walletModal: { isModalOpen, openModal, closeModal },
  } = useUiStateContext();

  const [mounted, setMounted] = useState(false);
  const [mobile, setMobile] = useState(true);

  useEffect(() => setMounted(true), []);
  if (!mounted) return null;

  if (!mobile || isModalOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "initial";
  }

  function appRedirect() {
    router.push(`${process.env.NEXT_PUBLIC_APP_URL}/stables/race`);
  }

  // async function enableWallet(wallet: WalletApp) {
  //   try {
  //     localStorage.setItem(
  //       "wallet",
  //       JSON.stringify(await Api.selectWallet(wallet))
  //     );
  //     setCTXWalletData(await Api.selectWallet(wallet));
  //     return await Api.selectWallet(wallet);
  //   } catch {
  //     localStorage.removeItem("wallet");
  //     setCTXWalletData(null);
  //     alert(`You must install ${wallet} browser extension first`);
  //   } finally {
  //     setIsOpen(false);
  //   }
  // }

  return (
    <>
      <h4>
        {title?.titleStart}
        <span> {title?.titleEnd}</span>
      </h4>
      <Button text={button.title} onClick={appRedirect} className={classNames(styles.button)} />
      {/* <Link href={button.url}>
        <a>
        <Button text={button.title} />
        </a>
      </Link> */}
      <WalletModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default Title;
