import { useMediaQuery } from "react-responsive";
import { IHomePageSections } from "../../../types/IHomePageSections";
import Characters from "./Characters/Characters";
import Community from "./Community/Community";
import DownloadingSection from "./DownloadingSection/DownloadingSection";
import FirstSection from "./FirstSection/FirstSection";
import LatestNewsMobile from "./LatestNews/LatesNewsMobile";
import LatestNews from "./LatestNews/LatestNews";
import Marketplace from "./Marketplace/Marketplace";
import OnlineStream from "./OnlineStream/OnlineStream";
import UpcomingRaces from "./UpcomingRaces/UpcomingRaces";

const Home = ({
  firstsectionData,
  latestNewsData,
  aboutAppData,
  onlineStreamData,
  upcomingRacesData,
  visitMarketplaceData,
  charactersSection,
  communitySectionData,
  qrCodeData,
  latestNewsDataTitle,
}: IHomePageSections) => {
  const isPhone = useMediaQuery({
    query: "(max-width: 576px)",
  });

  const findData = (data: any, fieldGroupName: string) => {
    let t = data?.data?.pages?.nodes.filter((el: any) => el.sections.sections.length !== 0);
    t = t && t.length > 0 && t[0].sections.sections.find((el: any) => el.fieldGroupName === fieldGroupName);

    return t;
  };
  const firstSectionData = findData(firstsectionData, "Page_Sections_Sections_HomepageFirstSection");

  const latestNewsTitle = findData(latestNewsDataTitle, "Page_Sections_Sections_HomepageLatestNewsSection");

  const aboutAppSectionData = findData(aboutAppData, "Page_Sections_Sections_HomepageAboutAppSection");
  const onlineStreamSectionData = findData(onlineStreamData, "Page_Sections_Sections_HomepageOnlineStreamSection");
  const upcomingRacesSectionData = findData(upcomingRacesData, "Page_Sections_Sections_HomepageUpcomingRacesSection");
  const visitMarketplaceSectionData = findData(
    visitMarketplaceData,
    "Page_Sections_Sections_HomepageVisitMarketplaceSection"
  );

  const charactersSectionData = findData(charactersSection, "Page_Sections_Sections_HomepageCharactersSection");
  const communityData = findData(communitySectionData, "Page_Sections_Sections_HomepageCommunitySection");

  const qrCodeContent = qrCodeData.data.posts.nodes[0].qrcode;
  return (
    <>
      {firstSectionData && (
        <FirstSection
          firstSectionData={firstSectionData}
          qrCodeData={!qrCodeData.loading && !qrCodeData.error && qrCodeContent}
        />
      )}
      {/* <div className="seperator"></div> */}
      {latestNewsData &&
        (!isPhone ? (
          <LatestNews latestNewsData={latestNewsData} latestNewsTitle={latestNewsTitle} />
        ) : (
          <LatestNewsMobile latestNewsData={latestNewsData} latestNewsTitle={latestNewsTitle} />
        ))}
      {aboutAppSectionData && (
        <DownloadingSection
          aboutAppSectionData={aboutAppSectionData}
          qrCodeData={!qrCodeData.loading && !qrCodeData.error && qrCodeContent}
        />
      )}
      {onlineStreamSectionData && <OnlineStream onlineStreamSectionData={onlineStreamSectionData} />}
      {upcomingRacesSectionData && <UpcomingRaces upcomingRacesSectionData={upcomingRacesSectionData} />}
      {visitMarketplaceSectionData && <Marketplace visitMarketplaceSectionData={visitMarketplaceSectionData} />}
      {charactersSectionData && <Characters charactersSectionData={charactersSectionData} />}
      {communityData && <Community communityData={communityData} />}
    </>
  );
};

export default Home;
