import classNames from "classnames";
import YouTube from "react-youtube";
import SectionTitle from "../../../SectionTitle/SectionTitle";
import styles from "./OnlineStream.module.scss";
// import videolayer from "../../../../assets/images/videolayer.png";
import { useState } from "react";
import { Play } from "../../../Icons";

const OnlineStream = ({ onlineStreamSectionData }: { onlineStreamSectionData: any }) => {
  const opts = {
    height: "551",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      controls: 0,
      rel: 0,
      showinfo: 0,
      mute: 1,
      loop: 1,
      enablejsapi: 1,
      modestbranding: 1,
    },
  };
  const [playVideo, setPlayVideo] = useState(false);
  const [playerPlayVideo, setplayerPlayVideo] = useState({
    playVideo: () => {},
  });

  const onReady = (event: any) => {
    setplayerPlayVideo(event.target);
  };
  const videoStart = () => {
    if (playerPlayVideo) {
      playerPlayVideo.playVideo();
    }
    setPlayVideo(true);
  };
  return (
    <section className={classNames(styles.element, "container")}>
      <div className={classNames(styles.wrapper)}>
        <SectionTitle
          sectionTitle={onlineStreamSectionData.onlineStreamSectionTitle}
          sectionDesc={onlineStreamSectionData.onlineStreamSectionDesc}
          className={classNames(styles.title)}
        />
        <div className={classNames(styles.video)}>
          <YouTube opts={opts} videoId={onlineStreamSectionData.onlineStreamSectionYoutubeId} onReady={onReady} />
          <div className={classNames(playVideo && styles.hide)}>
            <img src={onlineStreamSectionData.onlineStreamSectionImagePlaceholder?.sourceUrl} />
          </div>
          <div onClick={videoStart} className={classNames(styles.play, playVideo && styles.hide)}>
            <button>
              <Play />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OnlineStream;
