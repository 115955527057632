import classNames from "classnames";
import Link from "next/link";
import { useEffect, useRef } from "react";
import { Controller, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "../../../Button/Button";
import styles from "./LatestNews.module.scss";

const LatestNews = ({ latestNewsData, latestNewsTitle }: any) => {
  const news = latestNewsData?.data?.posts?.nodes;
  const title = latestNewsTitle?.latestSectionTitle || "";
  const swiper1Ref = useRef<any>();
  const swiper2Ref = useRef<any>();
  useEffect(() => {
    swiper1Ref.current.controller.control = swiper2Ref.current;
    swiper2Ref.current.controller.control = swiper1Ref.current;
  }, [news]);
  return (
    <section className={classNames(styles.element, "container")}>
      <div className={classNames(styles.content)}>
        <div className={classNames(styles.contentDescription)}>
          <span className={classNames(styles.title)}>{title}</span>
          <div>
            <Swiper
              modules={[Pagination, Navigation, Controller]}
              slidesPerView="auto"
              navigation
              pagination={{ clickable: true }}
              className={classNames(styles.contentSlider, "newsSlider")}
              onSwiper={(swiper) => {
                swiper1Ref.current = swiper;
              }}
            >
              {news && news.length > 0 ? (
                news?.map((t: any) => (
                  <SwiperSlide key={t.id} className={classNames(styles.contentSliderSlide)}>
                    <h4>{t.title}</h4>
                    <div dangerouslySetInnerHTML={{ __html: t.excerpt }} />
                    <Link href={`/news/article/${t.slug}`}>
                      <a>
                        <Button text="Read article" />
                      </a>
                    </Link>
                  </SwiperSlide>
                ))
              ) : (
                <SwiperSlide className={classNames(styles.contentSliderSlide)}>
                  <h4>There is not any news</h4>
                </SwiperSlide>
              )}
            </Swiper>
          </div>
        </div>
        <div className={classNames(styles.imageSide)}>
          <Swiper
            slidesPerView={1}
            className={classNames(styles.contentSlider, "imageSlider")}
            modules={[Controller]}
            onSwiper={(swiper) => {
              swiper2Ref.current = swiper;
            }}
          >
            {news &&
              news.length > 0 &&
              news?.map((t: any, i: number) => (
                <SwiperSlide key={t.id} className={classNames(styles.contentImageSlide)}>
                  <figure>
                    <img alt="News slide" src={t.featuredImage.node.sourceUrl} />
                  </figure>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default LatestNews;
