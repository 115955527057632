import classNames from "classnames";
import Link from "next/link";
import { useDarkModeContext } from "../../../../src/context/DarkModeContext";
import Button from "../../../Button/Button";
import styles from "./Marketplace.module.scss";

const Marketplace = ({ visitMarketplaceSectionData }: { visitMarketplaceSectionData: any }) => {
  const { darkMode } = useDarkModeContext();

  return (
    <section className={classNames(styles.element, "container")}>
      <div className={classNames(styles.leftSide)}>
        <span className={classNames(styles.title)}>{visitMarketplaceSectionData.visitMarketplaceSectionTitle}</span>
        <h4 className={classNames(styles.description)}>
          {visitMarketplaceSectionData.visitMarketplaceSectionDesc}{" "}
          <span>{visitMarketplaceSectionData.visitMarketplaceSectionGoldDesc}</span>{" "}
          {visitMarketplaceSectionData.visitMarketplaceSectionDescEnd}
        </h4>
        <p className={classNames(styles.text)}>{visitMarketplaceSectionData.visitMarketplaceSectionContent}</p>
        {!visitMarketplaceSectionData?.visitMarketplaceSectionDisableButton ? (
          <Link href={`${process.env.NEXT_PUBLIC_APP_URL}/stables/race`}>
            <a>
              <Button text={visitMarketplaceSectionData.visitMarketplaceSectionButton?.title} />
            </a>
          </Link>
        ) : (
          <Button
            text={visitMarketplaceSectionData.visitMarketplaceSectionButton?.title}
            className={visitMarketplaceSectionData?.visitMarketplaceSectionDisableButton && classNames(styles.disable)}
          />
        )}
      </div>
      <div className={classNames(styles.rightSide)}>
        <h4>{visitMarketplaceSectionData.visitMarketplaceSectionImageTitle}</h4>
        <p>{visitMarketplaceSectionData.visitMarketplaceSectionImageDesc}</p>
        <div className={classNames(styles.image, darkMode && styles.hasDarkMode)}>
          <figure>
            <img src={visitMarketplaceSectionData.visitMarketplaceSectionImage?.sourceUrl} alt="" />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default Marketplace;
